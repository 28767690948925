export const DEFAULT_COUNTRY_CODE = "de"
export const DEFAULT_LOCALE: AppLocale = "de"
export const FALLBACK_LOCALE: AppLocale = "en"

export const LOCALES = ["de", "en"] as const
export const PRERENDERED_COUNTRIES = ["de"] as const

export type AppLocale = (typeof LOCALES)[number]

export const COUNTRIES = ["de", "at"] as const

export type AppCountry = (typeof COUNTRIES)[number]

const countryCodeToLocale: Record<string, AppLocale> = {
  de: "de",
  at: "de",
} satisfies Record<AppCountry, AppLocale>

export function getLocaleForCountryCode(countryCode: string) {
  return countryCodeToLocale[countryCode] || FALLBACK_LOCALE
}

export function formatHrefLang(countryCode: string) {
  const language = getLocaleForCountryCode(countryCode)

  // https://developer.chrome.com/docs/lighthouse/seo/hreflang/?utm_source=lighthouse&utm_medium=lr#guidelines_for_hreflang_values
  return `${language}-${countryCode}`
}
