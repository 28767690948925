import { uniq } from "lodash"
import { routes } from "src/config/routes"
import { mapPath } from "src/tracking/mapping"
import { TrackingCart, TrackingLineItem, TrackingProduct } from "src/tracking/types"
import { roundPrice } from "src/utilities/price"
import {
  KlaviyoAddedToCartEvent,
  KlaviyoLineItemData,
  KlaviyoStartedCheckoutEvent,
  KlaviyoViewedItemEvent,
  KlaviyoViewedProductEvent,
} from "./types"

export function mapProductToKlaviyoViewedProductEvent(product: TrackingProduct): KlaviyoViewedProductEvent {
  return {
    ProductID: product.id,
    ProductName: product.name + (product.variantName ? ` (${product.variantName})` : ""),
    SKU: product.sku || "",
    Categories: product.categories || [],
    ImageURL: product.image,
    URL: product.href,
    Brand: "elvent",
    Price: product.grossOfferPrice,
    CompareAtPrice: product.grossFullPrice,
  }
}

export function mapKlaviyoViewedProductEventToKlaviyoViewedItemEvent(
  event: KlaviyoViewedProductEvent,
): KlaviyoViewedItemEvent {
  return {
    Title: event.ProductName,
    ItemId: event.ProductID,
    Categories: event.Categories,
    ImageUrl: event.ImageURL,
    Url: event.URL,
    Metadata: {
      Brand: event.Brand,
      Price: event.Price,
      CompareAtPrice: event.CompareAtPrice,
    },
  }
}

function mapLineItemToKlaviyoLineItemData(product: TrackingLineItem): KlaviyoLineItemData {
  const klaviyoProduct = mapProductToKlaviyoViewedProductEvent(product)

  return {
    ProductID: klaviyoProduct.ProductID,
    SKU: klaviyoProduct.SKU,
    ProductName: klaviyoProduct.ProductName,
    Quantity: product.quantity,
    ItemPrice: klaviyoProduct.Price,
    RowTotal: roundPrice(klaviyoProduct.Price * product.quantity),
    ProductURL: klaviyoProduct.URL,
    ImageURL: klaviyoProduct.ImageURL,
    ProductCategories: klaviyoProduct.Categories,
  }
}

export function mapCartChangeToKlaviyoAddedToCartEvent(
  cart: TrackingCart,
  lineItem: TrackingLineItem,
  quantityChange: number,
): KlaviyoAddedToCartEvent {
  return {
    $value: lineItem.grossOfferPrice * lineItem.quantity,
    AddedItemProductName: lineItem.name,
    AddedItemProductID: lineItem.id.toString(),
    AddedItemSKU: lineItem.sku || "unknown",
    AddedItemCategories: lineItem.categories || [],
    AddedItemImageURL: lineItem.image,
    AddedItemURL: lineItem.href,
    AddedItemPrice: lineItem.grossOfferPrice,
    AddedItemQuantity: quantityChange,
    ItemNames: cart.items.map((item) => item.name),
    CheckoutURL: mapPath(routes.checkout),
    Items: cart.items.map(mapLineItemToKlaviyoLineItemData),
  }
}

export function mapCartToKlaviyoStartedCheckoutEvent(cart: TrackingCart): KlaviyoStartedCheckoutEvent {
  return {
    $event_id: `${cart.id}_${Date.now()}`,
    $value: cart.grossValue,
    ItemNames: cart.items.map((item) => item.name),
    CheckoutURL: mapPath(routes.checkout),
    Categories: uniq(cart.items.flatMap((item) => item.categories || [])),
    Items: cart.items.map(mapLineItemToKlaviyoLineItemData),
  }
}
