import { isBrowser } from "src/utilities/env"
import { AppCountry, COUNTRIES, DEFAULT_COUNTRY_CODE, getLocaleForCountryCode } from "./localization"

export function getCountryCodeFromUrl() {
  if (!isBrowser) {
    throw new Error("getCountryCodeFromUrl can only be called in the browser")
  }

  const pathname = window.location.pathname
  const countryCodeSegment = pathname.split("/")[1]

  if (countryCodeSegment?.length === 2 && COUNTRIES.includes(countryCodeSegment as AppCountry)) {
    return countryCodeSegment as AppCountry
  }

  return DEFAULT_COUNTRY_CODE
}

export function getLocaleFromUrl() {
  if (!isBrowser) {
    throw new Error("getLocaleFromUrl can only be called in the browser")
  }

  const countryCode = getCountryCodeFromUrl()

  return getLocaleForCountryCode(countryCode)
}

export function getRelativeUrlWithoutLocale() {
  if (!isBrowser) {
    throw new Error("getLocaleFromUrl can only be called in the browser")
  }

  const { pathname, search } = window.location
  const countryCode = getCountryCodeFromUrl()

  if (pathname.startsWith("/" + countryCode)) {
    return pathname.substring(`/${countryCode}`.length) + search
  }

  return pathname + search
}
