import clsx from "clsx"
import { FC } from "react"
import { IconBaseProps } from "react-icons"
import {
  LuArrowDown,
  LuArrowRight,
  LuBellRing,
  LuCheck,
  LuChevronDown,
  LuChevronLeft,
  LuChevronRight,
  LuChevronsUpDown,
  LuCircleCheck,
  LuCopy,
  LuCopyCheck,
  LuCreditCard,
  LuDot,
  LuEye,
  LuEyeOff,
  LuHouse,
  LuImage,
  LuInfo,
  LuLoaderCircle,
  LuLogOut,
  LuMail,
  LuMapPin,
  LuMenu,
  LuPackage,
  LuPackageX,
  LuPhone,
  LuPlus,
  LuSearch,
  LuShoppingBag,
  LuSquarePen,
  LuTrash2,
  LuTruck,
  LuUserRound,
  LuX,
} from "react-icons/lu"

export type IconProps = IconBaseProps
export type Icon = FC<IconProps>

const wrap = (Icon: Icon) => {
  const Wrapped: Icon = ({ size = 20, ...props }) => {
    return <Icon {...props} size={size} className={clsx(props.className, "shrink-0")} />
  }
  return Wrapped
}

export const ArrowRightIcon = wrap(LuArrowRight)
export const BellRingIcon = wrap(LuBellRing)
export const CheckIcon = wrap(LuCheck)
export const ChevronDownIcon = wrap(LuChevronDown)
export const ChevronLeftIcon = wrap(LuChevronLeft)
export const ChevronRightIcon = wrap(LuChevronRight)
export const ChevronUpDownIcon = wrap(LuChevronsUpDown)
export const CopiedIcon = wrap(LuCopyCheck)
export const CopyIcon = wrap(LuCopy)
export const CreditCardIcon = wrap(LuCreditCard)
export const DotIcon = wrap(LuDot)
export const EditIcon = wrap(LuSquarePen)
export const EyeIcon = wrap(LuEye)
export const EyeOffIcon = wrap(LuEyeOff)
export const HomeIcon = wrap(LuHouse)
export const ImageIcon = wrap(LuImage)
export const InfoIcon = wrap(LuInfo)
export const MailIcon = wrap(LuMail)
export const MapPinIcon = wrap(LuMapPin)
export const MenuIcon = wrap(LuMenu)
export const NoShippingIcon = wrap(LuPackageX)
export const PackageIcon = wrap(LuPackage)
export const PhoneIcon = wrap(LuPhone)
export const PlusIcon = wrap(LuPlus)
export const SearchIcon = wrap(LuSearch)
export const ShippingIcon = wrap(LuTruck)
export const ShoppingBagIcon = wrap(LuShoppingBag)
export const SignOutIcon = wrap(LuLogOut)
export const StepCompleteIcon = wrap(LuCircleCheck)
export const TrashIcon = wrap(LuTrash2)
export const UserIcon = wrap(LuUserRound)
export const XIcon = wrap(LuX)

export const Spinner = wrap((props) => {
  return <LuLoaderCircle {...props} className={clsx("animate-spin", props.className)} />
})
