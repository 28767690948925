import { PlainUserData } from "src/integrations/lib/mapping"
import { getLocaleFromUrl } from "src/localization/client"
import { getUserId } from "src/tracking/state"
import { cleanObject } from "src/utilities/object"
import { ccm19GetAcceptedEmbeddings } from "../ccm19/api"
import { klaviyoPush } from "./api"

/** https://developers.klaviyo.com/en/reference/create_profile */
type KlaviyoPlainUserData = {
  email?: string
  phone_number?: string
  external_id?: string
  first_name?: string
  last_name?: string
  organization?: string
  locale?: string
  title?: string
}

function buildPlainUserData(userData: PlainUserData | undefined) {
  const klaviyoUserData = cleanObject({
    email: userData?.email,
    phone_number: userData?.phone,
    external_id: getUserId(),
    first_name: userData?.address?.firstName,
    last_name: userData?.address?.lastName,
    locale: getLocaleFromUrl(),
  } satisfies KlaviyoPlainUserData)

  return klaviyoUserData
}

let previousUserData: KlaviyoPlainUserData | undefined = undefined

export function klaviyoUpdateUserData(userData: PlainUserData | undefined) {
  const consented = ccm19GetAcceptedEmbeddings().some((consent) => consent.name === "Klaviyo")
  const consentedUserData = consented ? userData : undefined
  const klaviyoUserData = buildPlainUserData(consentedUserData)

  if (
    klaviyoUserData.email === previousUserData?.email &&
    klaviyoUserData.phone_number === previousUserData?.phone_number &&
    klaviyoUserData.external_id === previousUserData?.external_id &&
    klaviyoUserData.first_name === previousUserData?.first_name &&
    klaviyoUserData.last_name === previousUserData?.last_name &&
    klaviyoUserData.locale === previousUserData?.locale &&
    true
  ) {
    return
  }

  klaviyoPush("identify", klaviyoUserData)
  previousUserData = klaviyoUserData
}
