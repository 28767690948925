import { remember } from "@epic-web/remember"
import { getCountryCodeFromUrl, getLocaleFromUrl } from "src/localization/client"
import {
  AppCountry,
  AppLocale,
  COUNTRIES,
  DEFAULT_COUNTRY_CODE,
  getLocaleForCountryCode,
} from "src/localization/localization"
import { LayoutProps, PageProps } from "src/types/page"
import { isBrowser } from "src/utilities/env"

export type LocalizationState = {
  countryCode: AppCountry | undefined
  locale: AppLocale | undefined
}

// Using "remember" is necessary to preserve state across SSR render contexts.
const state = remember<LocalizationState>("localization", () => ({
  countryCode: undefined,
  locale: undefined,
}))

export function getCountryCodeOrThrow() {
  if (isBrowser) {
    return getCountryCodeFromUrl()
  }

  if (state.countryCode) {
    return state.countryCode
  }

  throw new Error("Country code is not set.")
}

export function getLocaleOrThrow() {
  if (!state.locale && isBrowser) {
    state.locale = getLocaleFromUrl()
  }

  if (state.locale) {
    return state.locale
  }

  throw new Error("Locale is not set.")
}

export function setLocalizationStateForTesting(newState: LocalizationState) {
  if (process.env.NODE_ENV !== "test") {
    throw new Error("This function is only for testing.")
  }

  state.locale = newState.locale
  state.countryCode = newState.countryCode
}

/**
 * On the server, the only way to know the locale is to look at the params in
 * the page props. But the page props are only available to the top-level page
 * component. To make it available to all components without passing it down to
 * each one, we save it here.
 */
export async function deriveStateFromRouteProps(props: PageProps | LayoutProps) {
  const params = await props.params
  const countryCode = COUNTRIES.includes(params.countryCode as AppCountry)
    ? (params.countryCode as AppCountry)
    : DEFAULT_COUNTRY_CODE

  state.countryCode = countryCode
  state.locale = getLocaleForCountryCode(countryCode)

  if (!state.locale) {
    throw new Error("Failed to determine locale for country code: " + state.countryCode)
  }
}
