import { updateUserData } from "src/integrations/lib/userData"
import { MedusaAddressPayload } from "src/types/medusa"
import { createPersistentStore } from "src/utilities/localStorage/create"

export type ContactData = {
  email: string
  phoneNormalized: string
  orderNumber: string
  address: MedusaAddressPayload | null
}

const defaultState: ContactData = {
  email: "",
  phoneNormalized: "",
  orderNumber: "",
  address: null,
}

export const contactStore = createPersistentStore(() => defaultState, { name: "contact" })

export function mergeContactData(contactData: Partial<ContactData>) {
  contactStore.setState(contactData)
  updateUserData()
}

export function getContactData() {
  return contactStore.getState()
}
