import { mapKlaviyoViewedProductEventToKlaviyoViewedItemEvent } from "./mapping"
import { KlaviyoAddedToCartEvent, KlaviyoStartedCheckoutEvent, KlaviyoViewedProductEvent } from "./types"

export type KlaviyoFunctionName =
  | "openForm"
  | "identify"
  | "track"
  | "trackViewedItem"
  | "account"
  | "cookieDomain"
  | "isIdentified"

export function klaviyoDebug(...args: any[]) {
  console.debug("🏳️ klaviyo:", ...args)
}

export function klaviyoPush(fnName: KlaviyoFunctionName, ...args: unknown[]) {
  klaviyoDebug(fnName, ...args)

  if (window.location.hostname === "localhost") {
    // On localhost, Klaviyo attemts to track to http://... which is redirected,
    // resulting in a CORS error: Access to XMLHttpRequest at
    // 'http://a.klaviyo.com/client/profiles/?company_id=Syateq' from origin
    // 'http://localhost:3000' has been blocked by CORS policy: Response to
    // preflight request doesn't pass access control check: Redirect is not
    // allowed for a preflight request.
    return
  }

  if (window.klaviyo) {
    window.klaviyo.push([fnName, ...args])
  }
}

// https://developers.klaviyo.com/en/docs/guide_to_integrating_a_platform_without_a_pre_built_klaviyo_integration#viewed-product-tracking-snippet
export function klaviyoTrackViewedProduct(viewedProductEvent: KlaviyoViewedProductEvent) {
  klaviyoPush("track", "Viewed Product", viewedProductEvent)

  const viewedItemEvent = mapKlaviyoViewedProductEventToKlaviyoViewedItemEvent(viewedProductEvent)

  klaviyoPush("trackViewedItem", viewedItemEvent)
}

// https://developers.klaviyo.com/en/docs/guide_to_integrating_a_platform_without_a_pre_built_klaviyo_integration#added-to-cart-tracking-snippet
export function klaviyoTrackAddedToCart(event: KlaviyoAddedToCartEvent) {
  klaviyoPush("track", "Added to Cart", event)
}

// https://developers.klaviyo.com/en/docs/guide_to_integrating_a_platform_without_a_pre_built_klaviyo_integration#started-checkout
export function klaviyoTrackStartedCheckout(event: KlaviyoStartedCheckoutEvent) {
  klaviyoPush("track", "Started Checkout", event)
}
