import { getLocaleForCountryCode } from "src/localization/localization"

/**
 * Takes an amount and a region, and converts the amount to a localized decimal format
 */
export const formatAmount = ({
  amount,
  currencyCode,
  ...rest
}: {
  amount: number
  currencyCode: string
  countryCode: string
  short?: boolean
  minimumFractionDigits?: number
  maximumFractionDigits?: number
}) => {
  return convertToLocale({
    amount: convertToDecimal(amount),
    currencyCode: currencyCode,
    ...rest,
  })
}

const convertToDecimal = (amount: number) => {
  return Math.round(amount) / 100
}

const convertToLocale = ({
  amount,
  currencyCode,
  countryCode,
  minimumFractionDigits,
  maximumFractionDigits,
  short,
}: {
  amount: number
  currencyCode: string
  countryCode: string
  minimumFractionDigits?: number
  maximumFractionDigits?: number
  short?: boolean
}) => {
  const locale = getLocaleForCountryCode(countryCode)

  const formatted = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(amount)

  if (short) {
    return formatted.replace(/[,.]00/, "")
  }

  return formatted
}

export function roundPrice<T extends number | undefined>(price: T): T {
  if (typeof price === "number") {
    return (Math.round(price * 100) / 100) as T
  }
  return price
}
