import { cleanObject } from "src/utilities/object"
import { ContactData } from "./state"

export type PlainAddressData = Partial<{
  firstName: string
  lastName: string
  street: string
  city: string
  region: string
  postalCode: string
  country: string
}>

export type PlainUserData = Partial<{
  email: string
  phone: string | undefined
  address?: PlainAddressData
}>

export function buildPlainUserData(data: Partial<ContactData>) {
  const address = cleanObject({
    firstName: data.address?.first_name,
    lastName: data.address?.last_name,
    street: data.address?.address_1,
    city: data.address?.city,
    region: "", // we do not collect this information during checkout
    postalCode: data.address?.postal_code,
    country: data.address?.country_code,
  } satisfies PlainAddressData)

  const userData = cleanObject({
    email: data.email || "",
    phone: data.phoneNormalized || "",
    address: address,
  } satisfies PlainUserData)

  return userData
}
