export function ccm19Debug(...args: any[]) {
  console.debug("🍪 ccm19:", ...args)
}

export function ccm19Show() {
  window.CCM?.openWidget()
}

export function ccm19GetAcceptedEmbeddings() {
  return window.CCM?.acceptedEmbeddings || []
}
