import { dataLayerUpdateUserData } from "src/integrations/google/enhancedConversions"
import { klaviyoUpdateUserData } from "../klaviyo/identify"
import { buildPlainUserData } from "./mapping"
import { getContactData } from "./state"

export function updateUserData() {
  const contactData = getContactData()
  const plainUserData = buildPlainUserData(contactData)

  dataLayerUpdateUserData(plainUserData)
  klaviyoUpdateUserData(plainUserData)
}
