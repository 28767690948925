const storefrontOrigin = process.env.NEXT_PUBLIC_STOREFRONT_ORIGIN || ""
const baseUrl = "https://flowers-and-me.twic.pics"

function mapDomains(src: string) {
  return src
    .replace(/^\//, storefrontOrigin + "/")
    .replace("https://flowers-and-me.shop/", baseUrl + "/shop/")
    .replace("https://storefront-prod-wcl6.onrender.com/", baseUrl + "/prod-shop/")
    .replace("https://storefront-demo-2e44.onrender.com/", baseUrl + "/demo-shop/")
    .replace("https://minio-server-prod.onrender.com/medusa-public-files/", baseUrl + "/files/")
    .replace("https://minio-server-a47q.onrender.com/medusa-public-files/", baseUrl + "/demo-files/")
    .replace("https://i.ytimg.com/", baseUrl + "/yt/")
    .replace("https://ucarecdn.com/", baseUrl + "/uc/")
}

export type TwicPicsOptions = {
  w?: number
  h?: number
  p?: number
  fit?: "cover" | "contain"
}

const emptyImage = "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="

export function toImageCdnUrl(url: string | null | undefined, options?: TwicPicsOptions) {
  if (!url) {
    return emptyImage
  }
  if (url.startsWith("data:")) {
    return url
  }
  if (url.startsWith("https://dummyimage.com")) {
    return url
  }

  const twicPicsUrl = mapDomains(url)

  // https://www.twicpics.com/docs/reference/transformations
  const transformations: string[] = []

  if (options?.w || options?.h) {
    const tp_resize = options?.fit === "contain" ? "contain" : "refit-cover"
    const tp_width = options?.w || "-"
    const tp_height = options?.h || "-"
    const padding = options.p ? `(${options?.p}p)` : ""

    transformations.push(`${tp_resize}=${tp_width}x${tp_height}${padding}`)
  }

  const params = transformations.length ? "?twic=v1/" + transformations.join("/") : ""

  if (url.startsWith(twicPicsUrl)) {
    return twicPicsUrl.split("?")[0] + params
  }

  return twicPicsUrl + params
}

export function toSquareProductImageCdnUrl(src: string | null | undefined) {
  return toImageCdnUrl(src, { w: 1200, h: 1200, fit: "contain" })
}

export function toVerticalProductImageCdnUrl(src: string | null | undefined) {
  return toSquareProductImageCdnUrl(src)
}

export function toHorizontalProductImageCdnUrl(src: string | null | undefined) {
  return toImageCdnUrl(src, { w: 1200, h: 900, fit: "cover", p: 15 })
}
