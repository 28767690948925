import { getGtmDebug } from "src/integrations/google/state"

export function dataLayerPush(...args: unknown[]) {
  console.debug("📊", ...args)

  window.dataLayer = window.dataLayer || []

  // GTM requires the use of "arguments", a regular array doesn't work.
  // biome-ignore lint/style/noArguments:
  window.dataLayer.push(arguments)
}

export type DataLayerEventData = {
  [key: string]: unknown
}

export function dataLayerPushEvent(name: string, data: DataLayerEventData) {
  dataLayerPush("event", name, { ...data, debug_mode: getGtmDebug() })
}
