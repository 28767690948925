import { useIsClient } from "src/hooks/useIsClient"
import { createPersistentStore } from "src/utilities/localStorage/create"

type State = {
  gtmDebug?: boolean
}

const defaultState: State = {}

const store = createPersistentStore(() => defaultState, { name: "debug" })

export function useGtmDebug() {
  const isClient = useIsClient()
  const value = store((state) => state.gtmDebug)

  return isClient && value
}

export function setGtmDebug(gtmDebug: boolean) {
  store.setState({ gtmDebug })
}

export function getGtmDebug() {
  return store.getState().gtmDebug
}
