import { localizePath, routes } from "src/config/routes"
import { META_DATA } from "src/metadata/constants"
import { TrackingCart, TrackingLineItem, TrackingOrder, TrackingProduct } from "src/tracking/types"
import { StorefrontDetailProduct, StorefrontDetailVariant, StorefrontListProduct } from "src/types/global"
import { StorefrontCart, StorefrontLineItem, StorefrontOrder } from "src/types/storefront"
import { reportError } from "src/utilities/error"
import { toSquareProductImageCdnUrl } from "src/utilities/images"
import { roundPrice } from "src/utilities/price"
import { isDummyVariant } from "src/utilities/product"

export function deductTax<T extends number | undefined>(price: T, taxRate: number): T {
  if (price) {
    if (taxRate) {
      return (price / (1 + taxRate / 100)) as T
    }
    return price as T
  }
  return undefined as T
}

function mapPrice(price: number): number {
  return roundPrice(price / 100)
}

export function mapPath(path: string) {
  return META_DATA.origin + localizePath(path)
}

export function mapListProductToTrackingProduct(product: StorefrontListProduct): TrackingProduct {
  const grossFullPrice = product.pricing.originalPrice || 0
  const grossOfferPrice = product.pricing.calculatedPrice || grossFullPrice
  const grossDiscount = grossFullPrice - grossOfferPrice
  const taxRate = product.pricing.taxRate
  const netFullPrice = deductTax(grossFullPrice, taxRate)
  const netOfferPrice = deductTax(grossOfferPrice, taxRate)
  const netDiscount = netFullPrice - netOfferPrice

  return {
    id: product.id.toString(),
    sku: undefined,
    href: mapPath(routes.format_product(product.handle)),
    name: product.title,
    variantName: undefined,
    image: toSquareProductImageCdnUrl(product.thumbnail),
    categories: product.categories?.map((category) => category.name),
    grossFullPrice: mapPrice(grossFullPrice),
    grossOfferPrice: mapPrice(grossOfferPrice),
    grossDiscount: mapPrice(grossDiscount),
    netFullPrice: mapPrice(netFullPrice),
    netOfferPrice: mapPrice(netOfferPrice),
    netDiscount: mapPrice(netDiscount),
    currencyCode: product.pricing.currencyCode.toUpperCase(),

    // TODO implement promotion tracking
    promotionId: undefined,
  }
}

export function mapDetailProductToTrackingProduct(
  product: StorefrontDetailProduct,
  variant: StorefrontDetailVariant,
): TrackingProduct {
  const grossFullPrice = variant.pricing.originalPrice || 0
  const grossOfferPrice = variant.pricing.calculatedPrice || grossFullPrice
  const grossDiscount = grossFullPrice - grossOfferPrice
  const taxRate = variant.pricing.taxRate
  const netFullPrice = deductTax(grossFullPrice, taxRate)
  const netOfferPrice = deductTax(grossOfferPrice, taxRate)
  const netDiscount = netFullPrice - netOfferPrice
  const handle = product.handle || ""
  const productName = product.title || ""
  const variantName = variant.name || ""

  if (!variant.id) {
    throw new Error("Variant has no id")
  }
  if (!handle) {
    throw new Error("Product has no handle")
  }
  if (!productName) {
    reportError("LineItem has no product name: " + JSON.stringify(product))
  }
  if (!variantName) {
    reportError("LineItem has no variant name: " + JSON.stringify(variant))
  }
  if (!product.title) {
    throw new Error("Product has no title")
  }
  if (!product.thumbnail) {
    throw new Error("Product has no thumbnail")
  }

  return {
    id: variant.id.toString(),
    sku: variant.sku,
    href: mapPath(routes.format_product(handle)),
    name: productName,
    variantName: variantName,
    image: toSquareProductImageCdnUrl(product.thumbnail),
    categories: (product.categories || []).map((category) => category.name),
    grossFullPrice: mapPrice(grossFullPrice),
    grossOfferPrice: mapPrice(grossOfferPrice),
    grossDiscount: mapPrice(grossDiscount),
    netFullPrice: mapPrice(netFullPrice),
    netOfferPrice: mapPrice(netOfferPrice),
    netDiscount: mapPrice(netDiscount),
    currencyCode: product.pricing.currencyCode.toUpperCase(),

    // TODO implement promotion tracking
    promotionId: undefined,
  }
}

export function mapCartChangeToTrackingLineItem(
  cart: Pick<StorefrontCart | StorefrontOrder, "region" | "items" | "discounts">,
  item: StorefrontLineItem,
  quantityChange?: number,
): TrackingLineItem {
  // {
  //   "discount_total": 1311,
  //   "original_tax_total": 1245,
  //   "original_total": 7800,
  //   "quantity": 2,
  //   "subtotal": 6555,
  //   "tax_total": 996,
  //   "total": 6240,
  //   "unit_price": 3900
  // }
  const quantity = quantityChange || item.quantity
  const grossFullPrice = item.variant.pricing.originalPrice || 0
  const grossOfferPrice = (item.total || 0) / quantity
  const grossDiscount = grossFullPrice - grossOfferPrice
  const taxRate = item.variant.pricing.taxRate
  const netFullPrice = deductTax(grossFullPrice, taxRate)
  const netOfferPrice = deductTax(grossOfferPrice, taxRate)
  const netDiscount = netFullPrice - netOfferPrice

  const index = cart.items?.findIndex((cartItem) => cartItem.id === item.id) ?? -1
  const discount_id = item.adjustments?.[0]?.discount_id
  const disountCode = cart.discounts?.find((discount) => discount.id === discount_id)?.code
  const handle = item.product.handle || ""
  const productName = item.title || ""
  const variantName = item.description || ""

  if (!handle) {
    reportError("LineItem has no handle: ", +JSON.stringify(item))
  }
  if (!productName) {
    reportError("LineItem has no product name: ", +JSON.stringify(item))
  }
  if (!variantName) {
    reportError("LineItem has no variant name: ", +JSON.stringify(item))
  }

  return {
    id: item.id,
    sku: item.variant.sku || undefined,
    href: mapPath(routes.format_product(handle)),
    name: productName,
    variantName: isDummyVariant(item.variant) ? undefined : variantName,
    image: toSquareProductImageCdnUrl(item.thumbnail),
    categories: [], // TODO add categories
    grossFullPrice: mapPrice(grossFullPrice),
    grossOfferPrice: mapPrice(grossOfferPrice),
    grossDiscount: mapPrice(grossDiscount),
    netFullPrice: mapPrice(netFullPrice),
    netOfferPrice: mapPrice(netOfferPrice),
    netDiscount: mapPrice(netDiscount),
    currencyCode: cart.region?.currency_code.toUpperCase(),
    promotionId: disountCode || item.variant.pricing.type !== "default" ? item.variant.pricing.type : undefined,
    quantity: quantity,
    index: index,
  }
}

function mapDiscounts(discounts: StorefrontCart["discounts"] | undefined) {
  return (discounts || []).map((discounts) => discounts.code.toLowerCase()).join(", ")
}

function mapCartItems(cart: Pick<StorefrontCart | StorefrontOrder, "region" | "items" | "discounts">) {
  return (cart.items || []).map((item) => mapCartChangeToTrackingLineItem(cart, item))
}

function mapTotals(data: StorefrontCart | StorefrontOrder) {
  const totalTax = data.tax_total || 0
  const grossShipping = (data.shipping_total || 0) + (data.shipping_tax_total || 0)
  const grossValue = data.total || 0
  const netShipping = data.shipping_total || 0
  const netValue = grossValue - totalTax - netShipping
  const currencyCode =
    (data.object === "cart"
      ? data.region?.currency_code?.toUpperCase()
      : data.object === "order"
        ? data.currency_code?.toUpperCase()
        : undefined) || ""

  if (!currencyCode) {
    reportError(`No currency code found for ${data.object}`)
  }

  return {
    totalTax,
    grossShipping,
    grossValue,
    netShipping,
    netValue,
    currencyCode,
  }
}

export function mapCartToTrackingCart(cart: StorefrontCart): TrackingCart {
  const { totalTax, grossShipping, grossValue, netShipping, netValue, currencyCode } = mapTotals(cart)

  return {
    id: cart.id,
    grossValue: mapPrice(grossValue),
    grossShipping: mapPrice(grossShipping),
    netValue: mapPrice(netValue),
    netShipping: mapPrice(netShipping),
    totalTax: mapPrice(totalTax),
    currencyCode: currencyCode,
    discountCode: mapDiscounts(cart.discounts),
    items: cart.items.map((item) => mapCartChangeToTrackingLineItem(cart, item)),
    shippingMethod: cart.shipping_methods[0]?.shipping_option.name,
    paymentMethod: cart.payment_session?.provider_id,
  }
}

export function mapOrderToTrackingOrder(order: StorefrontOrder): TrackingOrder {
  const { totalTax, grossShipping, grossValue, netShipping, netValue, currencyCode } = mapTotals(order)

  return {
    transactionId: order.id,
    grossValue: mapPrice(grossValue),
    grossShipping: mapPrice(grossShipping),
    netValue: mapPrice(netValue),
    netShipping: mapPrice(netShipping),
    totalTax: mapPrice(totalTax),
    currencyCode: currencyCode,
    discountCode: mapDiscounts(order.discounts),
    items: mapCartItems(order),
  }
}
