import { captureException, setContext } from "@sentry/nextjs"

export function addErrorContext(name: string, value: unknown) {
  setContext(name, { name: JSON.stringify(value, null, 2) })
}

export function reportError(message: string, cause?: unknown) {
  let combinedMessage = message
  let lastError = cause

  while (lastError) {
    if (lastError instanceof Error && lastError.message) {
      combinedMessage += `: ${lastError.message}`
      lastError = lastError.cause
    } else if (typeof lastError === "object") {
      addErrorContext("cause", lastError)
      combinedMessage += `: ${JSON.stringify(lastError, null, 2)}`
      lastError = undefined
    }
  }

  while (lastError instanceof Error && lastError.message) {
    combinedMessage += `: ${lastError.message}`
    lastError = lastError.cause
  }

  console.error(combinedMessage)
  captureException(new Error(combinedMessage))
}
