import { ccm19GetAcceptedEmbeddings } from "src/integrations/ccm19/api"
import { Ccm19EmbeddingName } from "src/integrations/ccm19/embeddings"
import { PlainUserData } from "src/integrations/lib/mapping"
import { cleanObject } from "src/utilities/object"
import { dataLayerPush } from "./dataLayer"

// https://support.google.com/google-ads/answer/13258081
// https://developers.google.com/tag-platform/tag-manager/server-side/common-event-data

type GooglePlainAddressData = {
  first_name?: string
  last_name?: string
  street?: string
  city?: string
  region?: string
  postal_code?: string
  country?: string
}

type GooglePlainUserData = {
  email_address?: string
  phone_number?: string
  address?: GooglePlainAddressData
}

function buildGooglePlainUserData(userData: PlainUserData | undefined) {
  const googlePlainAddress = cleanObject({
    first_name: userData?.address?.firstName,
    last_name: userData?.address?.lastName,
    street: userData?.address?.street,
    city: userData?.address?.city,
    region: userData?.address?.region,
    postal_code: userData?.address?.postalCode,
    country: userData?.address?.country,
  } satisfies GooglePlainAddressData)

  const googlePlainUserData = cleanObject({
    email_address: userData?.email,
    phone_number: userData?.phone,
    address: googlePlainAddress,
  } satisfies GooglePlainUserData)

  return googlePlainUserData
}

let previousUserData: GooglePlainUserData | undefined = undefined

export function dataLayerUpdateUserData(userData: PlainUserData | undefined) {
  const consented = ccm19GetAcceptedEmbeddings().some(
    (embedding) =>
      embedding.name === Ccm19EmbeddingName.GoogleAdsConversion ||
      embedding.name === Ccm19EmbeddingName.GoogleAdsRemarketing,
  )
  const consentedUserData = consented ? userData : undefined
  const googlePlainUserData = buildGooglePlainUserData(consentedUserData)

  if (
    googlePlainUserData.email_address === previousUserData?.email_address &&
    googlePlainUserData.phone_number === previousUserData?.phone_number &&
    googlePlainUserData.address?.first_name === previousUserData?.address?.first_name &&
    googlePlainUserData.address?.last_name === previousUserData?.address?.last_name &&
    googlePlainUserData.address?.street === previousUserData?.address?.street &&
    googlePlainUserData.address?.city === previousUserData?.address?.city &&
    googlePlainUserData.address?.region === previousUserData?.address?.region &&
    googlePlainUserData.address?.postal_code === previousUserData?.address?.postal_code &&
    googlePlainUserData.address?.country === previousUserData?.address?.country &&
    true
  ) {
    return
  }

  dataLayerPush("set", "user_data", googlePlainUserData)
  previousUserData = googlePlainUserData
}
