import { StorefrontDetailProduct, StorefrontDetailVariant } from "src/types/global"
import { MedusaPricedProduct } from "src/types/medusa"
import { StorefrontLineItem } from "src/types/storefront"

export function isGiftCard(item: StorefrontLineItem | MedusaPricedProduct | StorefrontDetailProduct) {
  // We could use a product type to determine if an item is a gift card but the
  // card object the service receives only has the product type id, not the full
  // product type object. We could fetch the product type object but that would
  // require an additional request for each item in the cart.

  return item.title === "Gutschein"
}

export function isLineItemDigital(item: StorefrontLineItem) {
  return item.description?.includes("Digital")
}

export function isVariantDigital(variant: StorefrontDetailVariant | null | undefined) {
  return variant?.name?.includes("Digital")
}

export function qualifiesForFreeShipping(price: number) {
  return price >= 4900
}

export function isDummyVariant(variant: StorefrontDetailVariant | null | undefined) {
  return variant?.name === "Standard"
}
